// Footer.jsx
import React from "react";
import { Link } from "react-router-dom";

const FooterSocials = () => {
  return (
    <>
      <div className="mb-4">&copy; Copyright 2023 | All Rights Reserved.</div>
      <div className="social-media-links d-flex flex-wrap gap-4">
        <Link
          to="#"
          className="icon d-flex align-items-center justify-content-center rounded-circle text-decoration-none"
        >
          <i className="fa fa-facebook"></i>
        </Link>
        <Link
          to="#"
          className="icon d-flex align-items-center justify-content-center rounded-circle text-decoration-none"
        >
          <i className="fa fa-youtube"></i>
        </Link>
        <Link
          to="#"
          className="icon d-flex align-items-center justify-content-center rounded-circle text-decoration-none"
        >
          <i className="fa fa-instagram"></i>
        </Link>
      </div>
    </>
  );
};

export default FooterSocials;
