// activityLayoute/Header.jsx
import React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import LogoLanding from "../../../assets/images/logo-white.png";
// banners
import TopDeals2 from "../../../assets/images/banners/Top-Deals-2.jpg";
import TopDeals from "../../../assets/images/banners/Top-Deals.jpg";
import TopExperiences2 from "../../../assets/images/banners/Top-Experiences-2.jpg";
import TopExperiences from "../../../assets/images/banners/Top-Experiences.jpg";
import NewExperiences2 from "../../../assets/images/banners/New-Experiences-2.jpg";
import NewExperiences from "../../../assets/images/banners/New-Experiences.jpg";

const MENU = [
  {
    name: "TOP EXPERIENCES",
    sku: "top-experiences",
    banners: [TopExperiences2, TopExperiences],
  },
  { name: "TOP DEALS", sku: "top-deals", banners: [TopDeals2, TopDeals] },
  {
    name: "NEW EXPERIENCES",
    sku: "new-experiences",
    banners: [NewExperiences2, NewExperiences],
  },
];
const Header = () => {
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();

  const handleClickViewList = (item) => {
    navigate(`/${item.sku}`, {
      state: { title: item.name, banners: item.banners },
    });
  };

  return (
    <>
      <header className="custom-header">
        <div className="container">
          <div className="row custom-row d-flex align-items-center">
            <div className="col-6 col-md-3">
              <div className="d-flex align-items-center gap-3">
                <button
                  type="button"
                  id="openSidebar"
                  className="btn btn-link p-0 border-0 text-white d-lg-none"
                >
                  <i className="bi bi-list fs-2"></i>
                </button>
                <Link to="/" className="logo">
                  <img
                    src={LogoLanding}
                    className="img-responsive"
                    alt="Logo"
                  />
                </Link>
              </div>
            </div>
            <div className="col-1 col-md-6">
              <div id="cssmenuu" className="common-menu-wrapper sidebar-drawer">
                <div className="logo d-flex align-items-center justify-content-between gap-3 border-bottom d-md-none">
                  <Link to={"index"}>
                    <img
                      src={LogoLanding}
                      className="img-responsive"
                      alt="Logo"
                    />
                  </Link>
                  <button
                    type="button"
                    id="closeSidebar"
                    className="btn-link p-0 border-0 text-white"
                  >
                    <i className="bi bi-x fs-18"></i>
                  </button>
                </div>
                <ul className="d-flex flex-column flex-lg-row flex-lg-wrap align-items-lg-center row-gap-2 column-gap-4 m-0 p-0">
                  {MENU.map((el, index) => (
                    <li key={index}>
                      <div
                        className={`cursor-pointer ${
                          pathname === `/${el.sku}` ? "text-custom-primary" : ""
                        }`}
                        onClick={() => handleClickViewList(el)}
                      >
                        {el.name}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-5 col-md-3">
              <div className="social d-flex flex-wrap align-items-center justify-content-end gap-2">
                <Link
                  to="https://touch-it.com.au/register"
                  className="btn logout"
                >
                  Register
                </Link>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
