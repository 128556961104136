const AppConstants = {
  routes: {
    home: "/",
    activityList: "/:page",
    booking: "/:page/:name",
    bookingCheckout: "/booking-checkout",
    bookingSuccess: "/booking-success",
  },
};

export default AppConstants;
