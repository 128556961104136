import { applyMiddleware, compose, createStore } from "redux";
import { createLogger } from "redux-logger";
import { thunk } from "redux-thunk";
import rootReducer, { initialState } from "../reducers";

const loggerMiddleware = createLogger();
const composeEnhancers =
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose();

export const configureStore = () => {
  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(thunk, loggerMiddleware))
  );

  return store;
};

export default configureStore;
