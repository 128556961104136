import AppConstants from "../appConstants";
import moment from "moment";
import axios from "axios";
// import { useNavigate } from "react-router-dom";

function getToken() {
  const currentDate = moment(new Date()).format("DDMMYYYY");
  const lastYear = moment().subtract(1, "years").format("YY");
  const token = Math.floor(+currentDate / +lastYear);
  return `${token}touchIt`;
}
/**
 * Returns flag if the user is already logged in
 * @void
 */
function isLoggedIn() {
  return !!localStorage.getItem("token");
}

/**
 * Removes user object from browser localstorage and redirects to login page
 * @void
 */
function throwBack(navigate) {
  if (!localStorage.getItem("token")) {
    navigate(AppConstants.routes.login);
    return false;
  }
}

function throwBackRev(navigate) {
  if (localStorage.getItem("token")) {
    navigate(AppConstants.routes.afterLogin);
  }
}

function logout(navigate) {
  localStorage.removeItem("token");
  localStorage.removeItem("userDetails");
  localStorage.removeItem("companyDetails");
  navigate(AppConstants.routes.login);
}

function setToken(data) {
  localStorage.setItem("token", JSON.stringify(data));
}

function getUserDetails() {
  let data = localStorage.getItem("userDetails");
  if (data) {
    data = JSON.parse(data);
    if (data.id) return data;
  }
  return null;
}

function getCompanyDetails() {
  let data = localStorage.getItem("companyDetails");
  if (data) {
    data = JSON.parse(data);
    if (data.id) return data;
  }
  return null;
}

const getIpAddress = async () => {
  try {
    const url = "https://ipinfo.io/json";
    // 'https://api.ipify.org?format=json';
    // 'https://ipinfo.io/json';

    // Fetch the IP address
    const ipResponse = await axios.get(url);
    // const ip = ipResponse.data.ip;
    // console.log("ipppp", ip);
    return ipResponse.data;
  } catch (error) {
    console.error("Error getting IP address:", error);
  }
};

function setMachineConfig(data) {
  localStorage.setItem("machineDetails", JSON.stringify(data));
}

function getMachineConfig() {
  let data = localStorage.getItem("machineDetails");
  if (data) {
    data = JSON.parse(data);
    if (data.id) return data;
  }
  return null;
}

function checkPagePermission () {
  let data = localStorage.getItem("companyDetails");
  if (data) {
    data = JSON.parse(data);
    if (data.id) return data;
  }
  return null;
}

const exportedObject = {
  getToken,
  logout,
  setToken,
  isLoggedIn,
  throwBack,
  throwBackRev,
  getUserDetails,
  getIpAddress,
  setMachineConfig,
  getMachineConfig,
  getCompanyDetails,
  checkPagePermission
};

export default exportedObject;
