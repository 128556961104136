import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import TestiImg1 from "./../assets/images/testimonials/1.png";
import TestiImg2 from "./../assets/images/testimonials/2.png";
import TestiImg3 from "./../assets/images/testimonials/3.png";

function TestimonialsComponent() {
  return (
    <>
      <div className="container">
        <h1 className="heading">Testimonials</h1>
        <div className="carousel-wrapper mt-4">
          <Carousel
            autoPlay={true}
            autoPlaySpeed={3000}
            responsive={{
              desktop: {
                breakpoint: {
                  max: 3000,
                  min: 1200,
                },
                items: 4,
                partialVisibilityGutter: 40,
              },
              mobile: {
                breakpoint: {
                  max: 575,
                  min: 0,
                },
                items: 1,
                partialVisibilityGutter: 30,
              },
              mobile1: {
                breakpoint: {
                  max: 991,
                  min: 576,
                },
                items: 2,
                partialVisibilityGutter: 30,
              },
              tablet: {
                breakpoint: {
                  max: 1199,
                  min: 992,
                },
                items: 3,
                partialVisibilityGutter: 30,
              },
            }}
          >
            <div className="item">
              <div className="join-blocks-img">
                <img src={TestiImg1} className="img-fluid" alt="Momento" />
              </div>
              <div className="join-blocks">
                <h4>Name Heading</h4>
                <span className="stars">
                  <i className="fa fa-star"></i>
                  <i className="fa fa-star"></i>
                  <i className="fa fa-star"></i>
                  <i className="fa fa-star"></i>
                  <i className="fa fa-star"></i>
                </span>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Vestibulum accumsan in elit sed consequat. In varius vehicula
                  ipsum id porttitor. Nunc posuere.
                </p>
              </div>
            </div>
            <div className="item">
              <div className="join-blocks-img">
                <img src={TestiImg2} className="img-fluid" alt="Momento" />
              </div>
              <div className="join-blocks">
                <h4>Name Heading</h4>
                <span className="stars">
                  <i className="fa fa-star"></i>
                  <i className="fa fa-star"></i>
                  <i className="fa fa-star"></i>
                  <i className="fa fa-star"></i>
                  <i className="fa fa-star"></i>
                </span>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Vestibulum accumsan in elit sed consequat. In varius vehicula
                  ipsum id porttitor. Nunc posuere.
                </p>
              </div>
            </div>
            <div className="item">
              <div className="join-blocks-img">
                <img src={TestiImg3} className="img-fluid" alt="Momento" />
              </div>
              <div className="join-blocks">
                <h4>Name Heading</h4>
                <span className="stars">
                  <i className="fa fa-star"></i>
                  <i className="fa fa-star"></i>
                  <i className="fa fa-star"></i>
                  <i className="fa fa-star"></i>
                  <i className="fa fa-star"></i>
                </span>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Vestibulum accumsan in elit sed consequat. In varius vehicula
                  ipsum id porttitor. Nunc posuere.
                </p>
              </div>
            </div>
            <div className="item">
              <div className="join-blocks-img">
                <img src={TestiImg1} className="img-fluid" alt="Momento" />
              </div>
              <div className="join-blocks">
                <h4>Name Heading</h4>
                <span className="stars">
                  <i className="fa fa-star"></i>
                  <i className="fa fa-star"></i>
                  <i className="fa fa-star"></i>
                  <i className="fa fa-star"></i>
                  <i className="fa fa-star"></i>
                </span>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Vestibulum accumsan in elit sed consequat. In varius vehicula
                  ipsum id porttitor. Nunc posuere.
                </p>
              </div>
            </div>
          </Carousel>
        </div>
      </div>
    </>
  );
}

export default TestimonialsComponent;
