import React from "react";
import { useNavigate } from "react-router-dom";

// Layoutes
import ActivityLayout from "./layouts/activityLayoute";

import HomeComponent from "./HomeComponent";
import ActivityListComponent from "./ActivityListComponent";
import PageNotFound from "./PageNotFound";
import Tour from "./tour/Tour";
import BookingSuccess from "./tour/BookingSuccess";

// TOUR


export const BookingSuccessComponent = () => {
  const navigate = useNavigate();
  return (
    <ActivityLayout navigate={navigate}>
      <BookingSuccess navigate={navigate} />
    </ActivityLayout>
  );
};

export const PageNotFoundComponent = () => (
  <ActivityLayout>
    <PageNotFound />
  </ActivityLayout>
);

export const HomeComponents = () => (
  <ActivityLayout>
    <HomeComponent />
  </ActivityLayout>
);

export const ActivityListComponents = () => (
  <ActivityLayout>
    <ActivityListComponent />
  </ActivityLayout>
);

export const TourComponent = () => {
  const navigate = useNavigate();
  return (
    <ActivityLayout navigate={navigate}>
      <Tour navigate={navigate} />
    </ActivityLayout>
  );
};
