import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { configureStore } from "./redux/store";
// import $ from 'jquery';
import "./index.scss";
import "bootstrap";

// import "./assets/css/animate.css";
import "./assets/css/fonts.css";
import "./assets/css/font-awesome.min.css";
// import "./assets/css/menumaker.css";
import "./assets/css/touch-it.scss";
import "./assets/css/custom.scss";
import "./assets/css/admin.scss";
import "./assets/css/embla.scss";
// import './assets/js/backtotop.js';

import App from "./App";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={configureStore()}>
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
