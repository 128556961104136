import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import AppConstants from "./appConstants";
import ScrollToTop from "./components/layouts/ScrollToTop";

// stripe
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { PageNotFoundComponent } from "./components";

import { indexRoutesTour } from "./routes";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY);

function App() {
  return (
    <>
      <Elements stripe={stripePromise}>
        <Router>
          <ScrollToTop />
          <Routes>
            {/* Tour */}
            <Route exact path={AppConstants.routes.home}>
              {indexRoutesTour.map((el, index) => (
                <Route
                  key={index}
                  exact={el.exact}
                  path={el.path}
                  index
                  element={<el.component />}
                />
              ))}
            </Route>
            <Route path="*" element={<PageNotFoundComponent />} />
          </Routes>
        </Router>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
          // transition: Bounce,
        />
      </Elements>
    </>
  );
}

export default App;
