import { CompanyConstants } from "../constants";

export const initialState = {
  companyDetails: {},
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case CompanyConstants.SET_COMPANY_DETAILS:
      return { ...state, companyDetails: action.payload };
    default:
      return { ...state };
  }
};

export default userReducer;
