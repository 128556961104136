import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Kangaroo from "./../assets/images/kangaroo.png";
import BestPrice from "./../assets/images/icons/best-price.png";
import TrustedBy from "./../assets/images/icons/trusted-by.png";
import Australian from "./../assets/images/icons/australian.png";
import Unbeatable from "./../assets/images/icons/unbeatable.png";
import TestimonialsComponent from "./TestimonialsComponent";
import { authCall } from "../services";
import { generateSku } from "./util/utils";

function ActivityListComponent() {
  const location = useLocation();
  const { title, banners } = location.state || {};
  const navigate = useNavigate();
  const { pathname } = location;
  const [details, setDetails] = useState([]);
  const [exclSkelArray] = useState(new Array(4).fill());
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getData() {
      setLoading(true);
      const path = pathname.split("/").pop();
      const resp = await authCall("getActivitySubPageData", {
        tags: path,
      });
      if (resp?.type === "success") {
        setDetails(resp?.payload || []);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
    getData();
  }, [pathname]);

  const handleClickBooking = (item) => {
    const name = generateSku(item.activity_name);
    navigate(`${pathname}/${name}`, { state: { activityId: item.id } });
  };

  return (
    <>
      <section className="banner">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-7 col-lg-8 order-md-last">
              <div
                id="myCarousel"
                className="custom-carousel-wrapper carousel slide carousel-fade"
              >
                <div className="carousel-indicators">
                  {banners.map((_, index) => {
                    return (
                      <button
                        type="button"
                        data-bs-target="#myCarousel"
                        data-bs-slide-to={index}
                        className={index === 0 ? "active" : ""}
                        aria-current="true"
                        aria-label={`Slide ${index}`}
                      ></button>
                    );
                  })}
                </div>

                <div className="carousel-inner">
                  {banners.map((el, index) => {
                    return (
                      <div
                        className={`carousel-item item ${
                          index === 0 ? "active" : ""
                        }`}
                        key={index}
                      >
                        <img src={el} alt={el} className="d-block w-100" />
                      </div>
                    );
                  })}
                </div>

                <button
                  className="carousel-control-prev d-md-none"
                  type="button"
                  data-bs-target="#myCarousel"
                  data-bs-slide="prev"
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button
                  className="carousel-control-next d-md-none"
                  type="button"
                  data-bs-target="#myCarousel"
                  data-bs-slide="next"
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </div>
            <div className="col-md-5 col-lg-4 order-md-first">
              <div className="banner-txt">
                <h2 className="animated fadeInRight">{title}</h2>
                <p>Experience it all</p>
                <Link
                  to="#"
                  className="book-now"
                  data-toggle="modal"
                  data-target="#filterModal"
                >
                  Search
                </Link>
              </div>
            </div>
          </div>
        </div>
        <img src={Kangaroo} className="maskot-header" alt={Kangaroo} />
      </section>

      <section className="keypoints py-5">
        <div className="container">
          <div className="row g-4">
            <div className="col-6 col-lg-3">
              <div className="keypoints-container d-flex flex-column flex-sm-row align-items-center justify-content-center justify-content-xl-start row-gap-2 column-gap-3 text-center text-sm-start">
                <div className="image-container d-flex align-items-center justify-content-center rounded-4">
                  <img src={BestPrice} alt={BestPrice} />
                </div>
                <h4 className="m-0">
                  Best price <span className="d-sm-block">guaranteed</span>
                </h4>
              </div>
            </div>
            <div className="col-6 col-lg-3">
              <div className="keypoints-container d-flex flex-column flex-sm-row align-items-center justify-content-center justify-content-xl-start row-gap-2 column-gap-3 text-center text-sm-start">
                <div className="image-container d-flex align-items-center justify-content-center rounded-4">
                  <img src={TrustedBy} alt={TrustedBy} />
                </div>
                <h4 className="m-0">
                Gold Coast <span className="d-sm-block">First</span>
                </h4>
              </div>
            </div>
            <div className="col-6 col-lg-3">
              <div className="keypoints-container d-flex flex-column flex-sm-row align-items-center justify-content-center justify-content-xl-start row-gap-2 column-gap-3 text-center text-sm-start">
                <div className="image-container d-flex align-items-center justify-content-center rounded-4">
                  <img src={Australian} alt={Australian} />
                </div>
                <h4 className="m-0">
                  Australian <span className="d-sm-block">owned</span>
                </h4>
              </div>
            </div>
            <div className="col-6 col-lg-3">
              <div className="keypoints-container d-flex flex-column flex-sm-row align-items-center justify-content-center justify-content-xl-start row-gap-2 column-gap-3 text-center text-sm-start">
                <div className="image-container d-flex align-items-center justify-content-center rounded-4">
                  <img src={Unbeatable} alt={Unbeatable} />
                </div>
                <h4 className="m-0">
                  Unbeatable <span className="d-sm-block">support</span>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="top-experiences result-listing py-3">
        <div className="container">
          <div className="row util-carousell">
            {loading &&
              exclSkelArray.map(() => (
                <div className="col-xs-6 col-md-4 col-lg-3 mb-4 item">
                  <div className="join-blocks">
                    <Skeleton
                      className="join-blocks-img"
                      style={{ borderRadius: 13 }}
                    />
                    <Skeleton count={1} width="80%" />
                    <Skeleton count={1} className="mt-3" width="60%" />
                    <Skeleton count={1} className="mt-3" width="30%" />
                  </div>
                </div>
              ))}

            {!loading &&
              details.map((el, index) => {
                return (
                  <div
                    key={index}
                    className="col-xs-6 col-md-4 col-lg-3 mb-4 item"
                  >
                    <div className="join-blocks">
                      <div className="join-blocks-img">
                        <img
                          src={el.profile_photo}
                          className="img-fluid cursor-pointer"
                          alt={el.activity_name}
                          onClick={() => handleClickBooking(el)}
                        />
                      </div>
                      <h4
                        className="cursor-pointer"
                        onClick={() => handleClickBooking(el)}
                      >
                        {el.activity_name}
                      </h4>
                      <span className="stars">
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star-o"></i>
                        (4.6)
                      </span>
                      <span className="price">
                        {el?.display_price?.advertisePrice && (
                          <>
                            from <del>${el?.display_price?.advertisePrice}</del>
                          </>
                        )}
                        <ins>${el?.display_price?.price}</ins>
                      </span>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </section>

      <section className="top-experiences testimonials mt-5 py-5">
        <TestimonialsComponent />
      </section>
    </>
  );
}

export default ActivityListComponent;
