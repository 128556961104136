// activityLayoute/Footer.jsx
import React from "react";
import { useNavigate } from "react-router-dom";
import LogoLanding from "../../../assets/images/logo-footer.png";
import kangaroo2 from "../../../assets/images/kangaroo2.png";
//banners
import GoldCoast2 from "../../../assets/images/banners/Gold-Coast-2.jpg";
import GoldCoast from "../../../assets/images/banners/Gold-Coast.jpg";
import Cairns2 from "../../../assets/images/banners/Cairns-2.jpg";
import Cairns from "../../../assets/images/banners/Cairns.jpg";
import PortDouglas2 from "../../../assets/images/banners/Port-Douglas-2.png";
import PortDouglas from "../../../assets/images/banners/Port-Douglas.png";
import SunshineCoast2 from "../../../assets/images/banners/Sunshine-Coast-2.jpg";
import SunshineCoast from "../../../assets/images/banners/Sunshine-Coast.jpg";
import Brisbane2 from "../../../assets/images/banners/Brisbane-2.jpeg";
import Brisbane from "../../../assets/images/banners/Brisbane.jpeg";
import Sydney2 from "../../../assets/images/banners/Sydney-2.jpg";
import Sydney from "../../../assets/images/banners/Sydney.jpg";
import Adelaide2 from "../../../assets/images/banners/Adelaide-2.jpeg";
import Adelaide from "../../../assets/images/banners/Adelaide.jpg";
import Perth2 from "../../../assets/images/banners/Perth-2.jpeg";
import Perth from "../../../assets/images/banners/Perth.jpg";
import Whitsundays2 from "../../../assets/images/banners/Whitsundays-2.png";
import Whitsundays from "../../../assets/images/banners/Whitsundays.png";
import Hobart2 from "../../../assets/images/banners/Hobart-2.jpg";
import Hobart from "../../../assets/images/banners/Hobart.jpg";
import FooterSocials from "../FooterSocials";
import { Link } from "react-router-dom";

const CITIES = [
  { name: "Gold Coast", sku: "gold-coast", banners: [GoldCoast2, GoldCoast] },
  { name: "Carins", sku: "carins", banners: [Cairns2, Cairns] },
  {
    name: "Port Douglas",
    sku: "port-douglas",
    banners: [PortDouglas2, PortDouglas],
  },
  {
    name: "Sunshine Coast",
    sku: "sunshine-coast",
    banners: [SunshineCoast2, SunshineCoast],
  },
  {
    name: "Whitsundays",
    sku: "whitsundays",
    banners: [Whitsundays2, Whitsundays],
  },
  { name: "Brisbane", sku: "brisbane", banners: [Brisbane2, Brisbane] },
  { name: "Sydney", sku: "sydney", banners: [Sydney2, Sydney] },
  { name: "Adelaide", sku: "adelaide", banners: [Adelaide2, Adelaide] },
  { name: "Hobart", sku: "hobart", banners: [Hobart2, Hobart] },
  { name: "Perth", sku: "perth", banners: [Perth2, Perth] },
];
const Footer = () => {
  const navigate = useNavigate();

  const handleClickViewList = (el) => {
    navigate(`/${el.sku}`, { state: { title: el.name, banners: el.banners } });
  };

  return (
    <footer className="mt-4 mt-md-5">
      <img src={kangaroo2} className="img-fluid maskot-footer mx-auto" alt="" />
      <div className="container-fluid mt-4">
        <div className="footer-logo mb-4">
          <Link to="/">
            <img src={LogoLanding} className="img-fluid" alt="" />
          </Link>
        </div>
        <div className="footer-menu">
          <ul className="d-flex flex-wrap justify-content-center row-gap-2 column-gap-3 column-gap-lg-4 mb-4 d-none">
            {CITIES.map((el, index) => (
              <li key={index}>
                <div
                  className="ftr-heading footer-custom-class"
                  onClick={() => handleClickViewList(el)}
                >
                  {el.name}
                </div>
              </li>
            ))}
          </ul>
          <FooterSocials />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
