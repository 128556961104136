import AppConstants from "../appConstants";
import {
  HomeComponents,
  ActivityListComponents,
  TourComponent,
  BookingSuccessComponent,
} from "../components";

export const indexRoutesTour = [
  {
    exact: true,
    path: "",
    name: "Home page",
    component: HomeComponents,
  },
  {
    exact: true,
    path: AppConstants.routes.activityList,
    name: "Activity list",
    component: ActivityListComponents,
  },
  {
    exact: true,
    path: AppConstants.routes.booking,
    name: "Detains and Booking",
    component: TourComponent,
  },
  {
    exact: true,
    path: AppConstants.routes.bookingSuccess,
    name: "Tour Booking Success",
    component: BookingSuccessComponent,
  },
];
